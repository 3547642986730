import Vue from 'vue'
import VueLogger from 'vuejs-logger'
import { DEVELOPMENT } from '@/config.js'

const options = {
  isEnabled: true,
  logLevel: DEVELOPMENT ? 'debug' : 'info',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: DEVELOPMENT,
  separator: '|',
  showConsoleColors: true
}

Vue.use(VueLogger, options)

// as soon as logging is established
Vue.$log.info(`starting ${APP_NAME}@${APP_VERSION}`)
