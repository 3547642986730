import { range, concat } from 'lodash'

export const DEVELOPMENT = !PRODUCTION
export const API_MOCK_ENABLED = true

const main = range(48, 57 + 1)
const numblock = range(96, 105 + 1)

export const keyCodes = {
  DigitAny: concat(main, numblock)
}
