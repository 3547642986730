export function closest (init, componentName) {
  const predicate = component =>
    component.$options._componentTag === componentName
  return closestBy(init, predicate)
}

export function closestBy (init, predicate) {
  let component = init
  do if (predicate(component)) return component
  while ((component = component && component.$parent))
}

export default {
  methods: {
    closest (componentName) {
      return closest(this, componentName)
    },
    closestBy (predicate) {
      return closestBy(this, predicate)
    }
  }
}
