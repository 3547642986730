import Vue from 'vue'
import { keyCodes } from '@/config.js'

import '@/common/logging'

// boostrap vue
import BootstrapVue from 'bootstrap-vue'

// i18n
import moment from 'moment'
import { i18n } from '@/i18n'
import 'moment/locale/de.js'
import { ApiService, CountryService, ActivityBus } from '@/common/api'

import '@/common/filters'
import { mapActions, mapGetters } from 'vuex'
import { INIT as INIT_AUTH } from '@/store/modules/auth/actions.type'
import { registerGlobalErrorHandler } from '@/common/error'
import { FETCH_ALL_FIRMS } from '@/store/modules/firms/actions.type'
import { AlertsMixin } from '@/mixins'

import '@/directives/registerGlobalDirectives.js'
import '@/components/registerGlobalComponents.js'
import App from '@/App.vue'

// router + store
import store from '@/store'
import router from './routes/'
import { sync } from 'vuex-router-sync'
import { whenLoaded as whenAbilitiesLoaded } from './ability'

Vue.use(BootstrapVue)

Vue.config.keyCodes = keyCodes
Vue.config.productionTip = false
sync(store, router)

moment.locale('de')

const instance = new Vue({
  el: '#app',
  mixins: [AlertsMixin],
  data: function () {
    return {
      activityBus: null,

      editing: {
        contract: false,
        crop: false,
        firm: false
      }
    }
  },
  computed: { ...mapGetters('auth', ['isLoggedIn', 'user', 'initialRoute']) },
  watch: {
    isLoggedIn (newVal) {
      if (newVal) {
        this.onLogin()
      } else {
        this.activityBus && this.activityBus.disconnect()
      }
    }
  },
  created () {
    this.activityBus = new ActivityBus(this.onActivityBusMessage)
    window.addEventListener('beforeunload', () => this.activityBus.disconnect())

    registerGlobalErrorHandler()
    this.initI18N()
    this[INIT_AUTH]()
  },
  methods: {
    ...mapActions('auth', [INIT_AUTH]),
    ...mapActions('firms', [FETCH_ALL_FIRMS]),
    async onLogin () {
      if (!this.isLoggedIn) {
        return
      }

      CountryService.get()

      await whenAbilitiesLoaded()

      this.redirectToStartRoute()
    },
    redirectToStartRoute () {
      const routePermissions = {
        vertragsdatenbank: 'contracts',
        sortendatenbank: 'crops',
        firmen: 'firms'
      }
      // resolve to known route
      const resolvedInit = this.$router.resolve(window.location.pathname)
      const resolvedRouteName = resolvedInit.route.name
      const canAccessInitialRoute = routePermissions[resolvedRouteName] && this.$can('access', routePermissions[resolvedRouteName])

      // use inital-route, fallback to user initial-route
      const routeTo = canAccessInitialRoute
        ? resolvedInit
        : this.$router.resolve({ name: this.initialRoute })

      if (this.$route.name !== routeTo.route.name) {
        // resolve to known route
        this.$log.info('redirecting user to ', routeTo.href)
        this.$router.replace(routeTo.href)
      }

      this.registerToActivityBus()
    },
    registerToActivityBus () {
      this.activityBus.connectPersistant()
    },
    /**
     * currently only handles websocket queries regarding "are you editing model/id?"
     * @param  ActivityMessage activityMessage
     * @return void
     */
    async onActivityBusMessage (activityMessage) {
      const ASK_VIEW_EVENT = this.activityBus.VIEW + '?'
      const askIds = activityMessage.payload.modelIds
      const askModel = String(activityMessage.payload.modelType)
      const routeId = Number(this.$route.params.id)
      const routeModel = String(this.$route.meta.model)
      if (activityMessage.event === ASK_VIEW_EVENT &&
        askIds && routeId && askIds.indexOf(routeId) > -1 &&
        askModel && routeModel && askModel === routeModel) {
        if (this.editing[askModel]) {
          this.$log.debug(`answering query from user:${activityMessage.from} event:${activityMessage.event}`)
          this.activityBus.answer(this.activityBus.VIEW, askModel, [routeId], activityMessage.from, activityMessage.eventId)
        }
      }
    },
    initI18N () {
      this.$log.debug('initI18N')

      const i18nMessages = {
        de: ApiService.get('i18n/de'),
        en: ApiService.get('i18n/en')
      }

      Object.entries(i18nMessages)
        .map(async ([locale, p]) => {
          const { data: { words, frontend, permissions, error } } = await p
          this.$i18n.setLocaleMessage(locale, {
            ...words, ...frontend, permissions, error
          })
          return locale
        })
    }
  },
  render: h => h(App),
  router,
  store,
  i18n
})

export default instance
