<template lang="pug">
div#header
  b-button(class="toggle-button" @click="$store.commit('drawer/toggle')" :pressed="drawer.showDrawer")
    MenuIconSvg(class="menu-icon")
    CloseMenuSvg(class="close-icon")
  FixedHeader(@change="headerIsFixed = Boolean($event)")
    // not `b-navbar` because vue-fixed-header lib adds classes
    nav.navbar.navbar-dark.navbar-expand-md(:class="{'is-fixed': headerIsFixed}")
      div(class="top-line")
        b-container
          b-navbar-brand(:title="`user inactive for ${inactiveSeconds}s`"
                          @mouseenter="inactiveSeconds = activityState.inactiveSeconds")
            RwaLogo.logo

          div(class="naviagtion-functions")
            h2(class="side-header") Sortenbeschreibung
            b-navbar-nav(class="ml-auto")
              b-nav-item-dropdown(:text="$i18n.locale" right)
                b-dropdown-item(@click="updateLocale('de')") de
                b-dropdown-item(@click="updateLocale('en')") en

            b-navbar-nav(class="ml-auto")
              b-nav-item-dropdown(:text="user.info?.name" right)
                b-dropdown-item(:title="$t('Benutzer-Einstellungen öffnen')" @click="showUserSettings=true")
                  div.py-2 {{$t('Benutzer-Konto')}}
                b-dropdown-divider
                b-dropdown-item(@click="$router.push('/logout')" :title="expiresRemaining")
                  span {{$t('Abmelden')}}
                  b-progress-bar(:value="expiresRemainingPercent"
                    :variant="remainingVariant"
                    :style="{height:'3px'}"
                    :max="100"
                    animated)
                b-dropdown-divider
                b-dropdown-item.text-right(disabled)
                  sub app: {{APP_VERSION}}
                  br
                  sub api: {{API_VERSION}}
            b-navbar-toggle(target="nav_collapse")
              BurgerIconSvg(class="menu-icon")
              CloseIconSvg(class="close-icon")

      b-container
        b-collapse(is-nav id="nav_collapse")
          div(class="nav-link-row")
            b-row
              b-col
                b-navbar-nav(class="nav-row")
                  li.nav-link(:class="{ 'disabled' : !$can('access','contracts') }")
                    router-link(:to="{name:'vertragsdatenbank',params:{id:currentContractId}}" v-t="'Verträge'")
                  li.nav-link(:class="{ 'disabled' : !$can('access','crops') }")
                    router-link(:to="{name:'sortendatenbank',params:{id:currentCropId}}" v-t="'Sorten'")
                  li.nav-link(:class="{ 'disabled' : !$can('access','firms') }")
                    router-link(:to="{name:'firmen',params:{id:currentFirmId}}" v-t="'Firmen'")
                  li.nav-link(:class="{ 'disabled' : !$can('access','users') }")
                    router-link(:to="{name:'users',params:{id:currentUserId}}" v-t="'Benutzerverwaltung'")
                  li.nav-link(:class="{ 'disabled' : !$can('access','reports') }")
                    router-link(:to="{name:'vertragsauswertungen'}" v-t="'Vertragsauswertungen'")
                  li.nav-link(:class="{ 'disabled' : !$can('access','reports') }")
                    router-link(:to="{name:'sortenauswertungen'}" v-t="'Sortenauswertungen'")

  img(src="@/assets/top-bg.png" class="bg-header")

  AuthenticatedUserSettingsModal(v-if="showUserSettings"
    :visible="showUserSettings" @hidden="showUserSettings = false"
    :user="user")
</template>

<script>
import moment from 'moment'

import RwaLogo from '@/assets/vector/logos/rwa.svg'
import MenuIconSvg from '@/assets/vector/icons/pfeil_li.svg'
import CloseMenuSvg from '@/assets/vector/icons/pfeil_re.svg'
import BurgerIconSvg from '@/assets/vector/icons/menu.svg'
import CloseIconSvg from '@/assets/vector/icons/close.svg'
import FixedHeader from 'vue-fixed-header'
import ApiService from '@/common/api'

import { mapState, mapGetters } from 'vuex'
import { updateLocale } from '@/i18n'

function createActivityMonitor (events = ['click', 'keyup', 'touchstart'], target = window.document) {
  let lastActivityTimestamp = (+new Date())
  const update = () => { lastActivityTimestamp = (+new Date()) }
  const opts = { passive: true }

  for (const event of events) {
    target.addEventListener(event, update, opts)
  }

  return {
    get since () {
      return moment(lastActivityTimestamp)
    },
    get inactiveSeconds () {
      return Math.round(moment.duration(moment().diff(moment(lastActivityTimestamp))).as('seconds'))
    },
    destroy () {
      for (const event of events) {
        target.removeEventListener(event, update, opts)
      }
    }
  }
}

export default {
  components: {
    RwaLogo,
    MenuIconSvg,
    CloseMenuSvg,
    FixedHeader,
    BurgerIconSvg,
    CloseIconSvg,
    AuthenticatedUserSettingsModal: () => import('@/components/common/AuthenticatedUserSettingsModal')
  },
  data () {
    return {
      headerIsFixed: false,

      APP_VERSION,
      API_VERSION: '',
      expiresRemainingPercent: 100,
      expiresRemaining: 100,
      expiresTimer: null,
      inactiveSeconds: 0,

      showUserSettings: false
    }
  },
  computed: {
    ...mapState(['drawer']),
    ...mapGetters('auth', ['user', 'sessionExpiresAt', 'sessionCreatedAt']),
    ...mapGetters('contract', { currentContractId: 'id' }),
    ...mapGetters('crop', { currentCropId: 'id' }),
    ...mapGetters('firms', { currentFirmId: 'id' }),
    ...mapGetters('user', { currentUserId: 'id' }),
    remainingVariant () {
      const p = this.expiresRemainingPercent
      return p < 10 ? 'danger' : p < 20 ? 'warning' : 'success'
    }
  },
  created () {
    ApiService
      .get('version')
      .then(({ data }) => { this.API_VERSION = data.version })

    this.expiresTimer = setInterval(this.updateExpiresRemaining, 23 * 1000)
    this.activityState = createActivityMonitor()
    this.updateExpiresRemaining()
  },
  beforeUnmount () {
    clearInterval(this.expiresTimer)
    this.activityState.destroy()
  },
  methods: {
    updateLocale,
    updateExpiresRemaining () {
      const expiresAt = this.sessionExpiresAt
      const remaining = moment.duration(expiresAt.diff(moment()))
      const remainingSeconds = Math.floor(remaining.as('seconds'))
      const percent = Math.round(Math.min(remainingSeconds / 6, 100))

      this.expiresRemaining = `Bei Inaktivität werden sie in ${remaining.humanize(true)} abgemeldet`
      this.expiresRemainingPercent = percent
      this.$log.info(`session remaining ${percent}%, inactivity for ${this.activityState.inactiveSeconds}s`)

      if (percent < 1) {
        if (this.$route.path !== '/logout') {
          this.$router.push({ name: 'logout', params: { reason: 'inactivity' } })
        }
      } else if (percent < 11 && this.activityState.inactiveSeconds < 1800) {
        this.$store.dispatch('auth/renew').then(() => {
          this.expiresRemainingPercent = 100
          this.$store.dispatch('auth/refresh')
        })
      } else if (percent < 5) {
        this.$store.dispatch('auth/refresh')
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/_base.scss";
.bg-header {
  max-width: 100%;
  height: auto;
}
.toggle-button {
  padding: 0;
  background: transparent !important;
  font-weight: bold;
  border: none;
  width: 50px;
  height: auto;
  box-shadow: none !important;
  z-index: 980;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  transition: 0.25s ease-in-out;
  .menu-icon {
    display: block;
    transition: all 0.4s ease;
    transform: rotate(0deg) scale(1, 1);
  }
  svg {
    margin-left: 15px;
    width: 15px;
  }
  .close-icon {
    display: none;
    transition: all 0.4s ease;
    transform: rotate(45deg) scale(0, 0);
  }
  &.active {
    right: calc(33% - 50px);

    @media (max-width: 1500px) {
      right: calc(40% - 50px);
    }
    @include media-breakpoint-down(lg) {
      right: 460px;
    }
    @include media-breakpoint-down(xs) {
      right: calc(95% - 30px);
    }

    .close-icon {
      display: block;
      transition: all 0.4s ease;
      transform: rotate(0deg) scale(1, 1);
    }
    .menu-icon {
      display: none;
      transition: all 0.4s ease;
      transform: rotate(-45deg) scale(0, 0);
    }
  }
}
#header {
  background: white;
  padding: 0 0 15px;

  .navbar-nav .router-link-exact-active {
    font-weight: bold;
  }
  .nav-link-row {
    width: 100%;
    > .row {
      @media only screen and (max-width: 767px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .nav-row {
    &.navbar-nav {
      flex-direction: row;
      @media only screen and (max-width: 992px) {
        flex-wrap: wrap;
      }
      margin: 0 -0.5em;
      .nav-item {
        padding: 0.5rem;
        a {
          padding: 0;
        }
        .dropdown-menu{
          .dropdown-item{
            padding: 0.25rem 1.5rem;
          }
        }
      }
    }
  }
  .navbar {
    a{
      color:black;
    }
    .disabled > a{
      color: $grey1;
    }
    width: 100%;
    z-index: 900;
    padding: 0;
    padding: 15px 0 5px;
    background: white;
    border-top: 10px solid #009640;
    flex-wrap: wrap;
    @media only screen and (max-width: 767px) {
      padding-bottom: 0;
    }
    > .container {
      flex-wrap: wrap;
    }
    .side-header {
      font-family: "Klavika", sans-serif;
      font-weight: bold;
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 0;
      line-height: 1.37;
      @media only screen and (max-width: 767px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 575px) {
        font-size: 14px;
        position: absolute;
        left: 15px;
        top: 31px;
      }
    }
    &.is-fixed {
      position: fixed;
      border-bottom: 1px solid #e4e4e4;
      padding-top: 5px;
      padding-bottom: 0;
      .navbar-brand {
        height: 30px;
        > svg {
          height: 30px;
        }
      }
      > .top-line {
        > .container {
          &:before {
            top: -15px;
          }
        }
      }
      .side-header {
        font-size: 18px;
        @media only screen and (max-width: 575px) {
          font-size: 14px;
        }
      }
    }
    > .top-line {
      border-bottom: 1px solid #e4e4e4;
      width: 100%;
      > .container {
        display: flex;
        padding-bottom: 5px;
        flex-direction: row;
        position: relative;
        &:before {
          position: absolute;
          top: -25px;

          width: 200px;
          height: 10px;

          content: " ";

          background: $lightgreen;
        }
        .naviagtion-functions {
          align-items: flex-end;
          display: flex;
          flex-basis: auto;
          flex-grow: 1;
        }
        .nav-link {
          padding: 0 0.5em;
        }
      }
    }
    .navbar-brand {
      padding: 0;
      margin: 0 0;
      width: 200px;
      height: 57px;
      margin-right: 20px;
      @media only screen and (max-width: 767px) {
        height: 30px;
        width: auto;
        > svg {
          height: 30px !important;
          width: auto !important;
        }
        @media only screen and (max-width: 575px) {
          margin-bottom: 20px;
        }
      }
      > svg {
        width: 100%;
        display: inline-block;
        width: 200px;
        height: 57px;
        > svg {
          margin-top: 0;
          width: auto;
          height: 100%;
        }
      }
    }
  }
  h2 {
    line-height: 2em;
  }
}
img {
  object-fit: cover;
  width: 100vw;
}
.navbar-toggler {
  padding-right: 0;
  height: 30px;
  .close-icon {
    display: none;
  }
  &[aria-expanded="true"] {
    .close-icon {
      display: block;
    }
    .menu-icon {
      display: none;
    }
  }
}
#header .nav-row.navbar-nav {
  @media only screen and (max-width: 767px) {
    .nav-link {
      width: 100%;
    }
  }
}
</style>
